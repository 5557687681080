var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "domains-start-company-page" } },
    [
      _vm._t("body", function () {
        return [_c(_vm.currentSlide, { tag: "component" })]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }