<template>
  <div id="domains-start-company-page">
    <slot name="body">
      <component :is="currentSlide" />
    </slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { tagSession } from '@/common/modules/inspectlet'
export default {
  name: 'DomainsStartCompanyPage',
  components: {
    StartOrAddBusiness: () => import('@/components/StagelineV2/domainsFirst/StartOrAddCompany/StartOrAddBusiness.vue'),
    SelectJurisdiction: () => import('@/components/StagelineV2/domainsFirst/StartOrAddCompany/SelectJurisdiction'),
    SelectEntityType: () => import('@/components/StagelineV2/domainsFirst/StartOrAddCompany/SelectEntityType'),
    CompanyName: () => import('@/components/StagelineV2/domainsFirst/StartOrAddCompany/CompanyName'),
    SimilarCompanyName: () => import('@/components/StagelineV2/domainsFirst/StartOrAddCompany/SimilarCompanyName'),
    ConfirmCompany: () => import('@/components/StagelineV2/domainsFirst/StartOrAddCompany/ConfirmCompany'),
    CompanyCreated: () => import('@/components/StagelineV2/domainsFirst/StartOrAddCompany/CompanyCreated'),
  },
  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'currentSlide',
    ]),
    ...mapGetters('stageline', [
      'accountCompanies',
    ]),
  },
  mounted() {
    tagSession({ stagelineV2: 'createCompany' })

    this.resetCompanyData()
    this.setCurrentSlide('StartOrAddBusiness')
    if (!this.accountCompanies?.length) this.getAccountCompanies()
  },
  methods: {
    ...mapActions('stagelineCreateCompany', [
      'resetCompanyData',
      'setCurrentSlide',
    ]),
    ...mapActions('stageline', [
      'getAccountCompanies',
    ]),
  },
}
</script>
